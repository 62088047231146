exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agreement-js": () => import("./../../../src/pages/agreement.js" /* webpackChunkName: "component---src-pages-agreement-js" */),
  "component---src-pages-attracted-customers-js": () => import("./../../../src/pages/attracted-customers.js" /* webpackChunkName: "component---src-pages-attracted-customers-js" */),
  "component---src-pages-change-password-js": () => import("./../../../src/pages/change-password.js" /* webpackChunkName: "component---src-pages-change-password-js" */),
  "component---src-pages-customer-orders-js": () => import("./../../../src/pages/customer-orders.js" /* webpackChunkName: "component---src-pages-customer-orders-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-payout-history-js": () => import("./../../../src/pages/payout-history.js" /* webpackChunkName: "component---src-pages-payout-history-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */)
}

